import { AxiosError, type AxiosResponse } from 'axios';
import { useState } from 'react';
import { MdClose, MdImage, MdOutlineErrorOutline } from 'react-icons/md';
import classNames from 'classnames';
import { Col, Row } from 'darrius';

import { RemoveImageDialog } from './RemoveImageDialog';
import { Dialog } from '../../../../atoms';
import Toast, { showToast } from '../../../../atoms/Toast/Toast';

import { notifyErrorClient } from '../../../../../services/notifyError/client';

import { axiosInstance } from '../../../../../utils/axiosInstance';
import { errorPrefix } from '../../../../../utils/errorPrefix';

import styles from './ImageUpload.module.scss';

interface IImageUpload {
  onSaveImage: (img: Object) => void;
  className?: string;
}

const WARN = {
  imageNotAllowed: 'Imagem inválida ou não permitida.',
  connectionError: 'Erro ao subir a imagem. Tente novamente.',
  sizeNotAllowed: 'Só são permitidas imagens com até 8mb.',
  default:'1 imagem com até 8mb. Apenas JPG e PNG',
};

const IMAGE_SIZE_LIMIT_IN_MB = 8 * 1024 * 1024; //8 MB

export function ImageUpload({ onSaveImage, className }: IImageUpload) {
  const [message, setMessage] = useState(WARN.default);
  const [preview, setPreview] = useState<string | null>(null);
  const [isValidImg, setIsValidImg] = useState(true);
  const [image, setImage] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const uploadClass = classNames(
    className,
    'upload',
  );

  const uploadImage = async (file) => {

    let formData = new FormData();
    formData.append('image', file);

    const headers = {
      'Content-Type': 'multipart/form-data',
      'Accept': 'application/hal+json',
      'Accept-Version': 7,
    };
    try {
      setMessage('');
      await axiosInstance({
        headers,
        url: `${process.env.NEXT_PUBLIC_API_MOBILE_URL}/v1/image_validations/validate`,
        method: 'POST',
        data: {
          images: [formData.get('image')]
        }
      }, false)
        .then(response => {
          onSaveImage(response.data);
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response != null) {
          const { status } = error.response as AxiosResponse;
          if (status === 404) {
            setMessage(WARN.connectionError);
            showToast({ message: WARN.connectionError, type: 'error' });
          };
          if (status === 422) {
            setMessage(WARN.imageNotAllowed);
            showToast({ message: WARN.imageNotAllowed, type: 'error' });

          };
          if (status === 401) {
            setMessage(WARN.connectionError);
            showToast({ message: WARN.connectionError, type: 'error' });
          }
          if (status >= 500) {
            setMessage(WARN.connectionError);
            showToast({ message: WARN.connectionError, type: 'error' });
          }
        } else {
          setMessage(WARN.connectionError);
          showToast({ message: WARN.connectionError, type: 'error' });
        }
      }
      notifyErrorClient(errorPrefix('[Upload-Image]', error));
      setImage(null);
      setIsValidImg(false);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file && file.size <= IMAGE_SIZE_LIMIT_IN_MB) {
      setPreview(URL.createObjectURL(file));
      setIsValidImg(true);
      setImage(file);

      await uploadImage(file);
    } else {
      setIsValidImg(false);
      setMessage(WARN.sizeNotAllowed);
      showToast({ message: WARN.sizeNotAllowed, type: 'error' });
    }
  };

  const handleRemoveImage = () => {
    setPreview(null);
    setImage(null);
    setMessage(WARN.default);
    setIsValidImg(true);
    setIsDialogOpen(false);
    onSaveImage(null);
  };

  return (
    <div className={uploadClass}>
      <Dialog.Root open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <Row>
          <Col className="col-sm-12 center">
            <div className={styles['upload-image']}>
              {image && isValidImg ? (
                <div className={styles['image-preview']}>
                  <img src={preview} alt="Preview" />
                  <Dialog.Trigger asChild>
                    <button
                      type="button"
                      onClick={() => setIsDialogOpen(true)}
                      className={styles['remove-image-button']}
                    >
                      <MdClose />
                    </button>
                  </Dialog.Trigger>
                  <RemoveImageDialog handleRemoveImage={handleRemoveImage} />
                </div>
              ) : (
                <form>
                  <label
                    htmlFor="upload"
                    className={styles['custom-upload-button']}
                  >
                    <span>
                      <MdImage /> Adicionar foto (opcional)
                    </span>
                  </label>
                  <input
                    type="file"
                    name="image"
                    id="upload"
                    onChange={handleFileChange}
                    accept='image/jpeg, image/png'
                  />
                </form>
              )}
              {message && (
                <>
                  {!isValidImg ? (
                    <div className={styles.warn_error_section}>
                    <MdOutlineErrorOutline />
                    <span className={styles.warn_message}>{message}</span>
                  </div>
                  ) : (
                    <p className={styles.warn_message}>{message}</p>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>

        <Toast />
      </Dialog.Root>
    </div>
  );
}

export default ImageUpload;
